<template>
  <div>
    <v-container>
      <h1 class="pb-6">My Livestock</h1>
      <v-row>
        <v-col v-for="(menu, idx) in menus" :key="idx" cols="6" md="3" sm="3">
          <v-card ripple link :to="'/' + menu.url" rounded="lg" class="pa-6">
            <v-img class="ma-16" :src="getImage(menu.img)"></v-img>
            <p class="text-center">{{ menu.title }}</p>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  data: function() {
    return {
      menus: [
        {
          img: "register_edit_livestock.svg",
          title: "Register/Edit Livestock",
          url: "register-edit-livestock",
        },
        {
          img: "set_up_sensors.svg",
          title: "Set Up Sensors",
          url: "set-up-sensors",
        },
        {
          img: "livestock_observations.svg",
          title: "Livestock Observations",
          url: "livestock-observations",
        },
        {
          img: "sell_trade.svg",
          title: "Sell/Trade",
          url: "sell-trade",
        },
        {
          img: "send_to_abattoir.svg",
          title: "Send to Abattoir",
          url: "send-to-abattoir",
        },
        {
          img: "ship_and_track.svg",
          title: "Ship and Track",
          url: "ship-and-track",
        },
        {
          img: "farm_audit.svg",
          title: "Farm Audit",
        },
        {
          img: "my_documents.svg",
          title: "My Documents",
          url: "livestock/my-documents",
        },
      ],
    };
  },
  methods: {
    getImage(img) {
      return "/img/livestock/" + img;
    },
  },
};
</script>
